<template>
  <div>
    <page-title />
    <b-alert
      :show="!!error"
      variant="danger"
      v-text="error"
    />
    <b-card>
      <b-form @submit.prevent="updatePassword()">
        <b-form-group>
          <b-input-group>
            <b-form-input
              v-model="form.current"
              required
              :type="inputType"
              placeholder="Current password"
              autofocus
            />
            <b-input-group-append>
              <b-button @click="show = !show">
                <b-icon :icon="show ? 'eye' : 'eye-fill'" />
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group
          :invalid-feedback="passwordStrongError"
          :state="passwordStrongValidationState"
        >
          <b-form-input
            v-model="form.new"
            v-debounce="checkPasswordStrong"
            required
            :type="inputType"
            placeholder="New password"
            :state="passwordStrongValidationState"
          />
        </b-form-group>
        <b-form-group
          :state="confirmationValidationState"
          invalid-feedback="Passwords must be equal."
        >
          <b-form-input
            v-model="form.confirmation"
            required
            :type="inputType"
            placeholder="Confirm password"
            :state="confirmationValidationState"
          />
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="!canSubmit"
        >
          <b-spinner
            v-if="loading"
            small
          /> Update
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import ApiService from '@/ApiService'
import { getRequestErrorText } from '@/utils'
import PageTitle from '@/components/PageTitle'

export default {
  name: 'UpdatePassword',
  components: { PageTitle },
  data () {
    return {
      loading: false,
      form: {
        current: '',
        new: '',
        confirmation: ''
      },
      error: '',
      show: false,
      passwordStrongError: ''
    }
  },
  computed: {
    canSubmit () {
      return this.form.current && this.form.new && this.form.confirmation && this.isPasswordsEquals && !this.passwordStrongError
    },
    isPasswordsEquals () {
      return this.form.new === this.form.confirmation
    },
    confirmationValidationState () {
      return this.form.new || this.form.confirmation ? (this.form.new === this.form.confirmation ? null : false) : null
    },
    inputType () {
      return this.show ? 'text' : 'password'
    },
    passwordStrongValidationState () {
      return this.passwordStrongError ? false : null
    }
  },
  watch: {
    form: {
      handler () {
        this.error = ''
      },
      deep: true
    },
    'form.new' () {
      this.passwordStrongError = ''
    }
  },
  methods: {
    updatePassword () {
      this.error = ''
      this.loading = true

      ApiService.post('/user/password/update', this.form)
        .then(() => {
          this.$router.push({ name: 'Home' })
        })
        .catch(error => {
          this.error = getRequestErrorText(error, '')
        })
        .finally(() => {
          this.loading = false
        })
    },
    checkPasswordStrong (pass) {
      if (pass) {
        this.passwordStrongError = ''

        ApiService.post('/user/password/is-strong-enough', { pass })
          .catch(error => {
            this.passwordStrongError = getRequestErrorText(error, '')
          })
      }
    }
  }
}
</script>
